<template>
<div>
  <a href=" " target="_blank">辽ICP备2022002400号-1</a>
</div>
</template>

<script>
export default {
  name: "DeFooter"
}
</script>

<style scoped>

</style>