<template>
  <div>
    <CustomOrderInput :addOrder="addOrder" :changUrl="changUrl" v-if="print === 'false'" :setUsername="setUsername">

    </CustomOrderInput>
    <OrderList :Product="Product" :setOtherText="setOtherText" v-if="print === 'false'" :deleteTodo="deleteTodo">

    </OrderList>
    <PrintList v-if="print === 'true'" :Product="Product" :OtherText="OtherText" :userName="userName" :changUrl="changUrl">

    </PrintList>
    <MainFooter :isPrint="isPrint"></MainFooter>
  </div>
</template>

<script>
import OrderList from "@/components/OrderList";
import MainFooter from "@/components/MainFooter";
import CustomOrderInput from "@/components/CustomOrderInput";
import PrintList from "@/components/PrintList";

export default {
  name: "CustomOrder",
  components:{OrderList,MainFooter,CustomOrderInput,PrintList},
  props:["changUrl"],
  data(){
    return {
      Product: [

      ],
      OtherText:"",
      print:"false",
      userName:"",
    }
  },
  methods:{
    //添加一个Obj
    addOrder(Obj){
      this.Product.unshift(Obj)
    },
    setOtherText(text){
      this.OtherText = text;
    },
    isPrint(val){
      this.print = val;
    },
    setUsername(text){
      this.userName = text;
    },
    deleteTodo(id){
      this.Product = this.Product.filter( Product => Product.id !== id )
    },
  }

}

</script>

<style scoped>

</style>