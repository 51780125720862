<template>
  <div>
      <form>
        名称：<input v-model="Product.productName">
        <select v-model="Product.productStrength">
          <option value="">请选择宽度</option>
          <option value="30">30</option>
          <option value="40">40</option>
          <option value="50">50</option>
          <option value="60">60</option>
        </select>
        <select v-model="Product.productType">
          <option value="">请选择类型</option>
          <option value="墙板">墙板</option>
          <option value="棚板">棚板</option>
        </select>
      </form>
    <button @click="add">添加</button>
    <table>
      <tr>
        <th>名称</th>
        <th>类型</th>
        <th>宽度</th>
      </tr>
      <tr v-for="Ps in ProductList" :key="Ps.productId">
        <th>{{Ps.productName}}</th>
        <th>{{Ps.productType}}</th>
        <th>{{Ps.productStrength}}</th>
        <th><button @click="deleteProduct(Ps.productId)">删除</button></th>
      </tr>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import {nanoid} from "nanoid";

export default {
  name: "addProduct",
  props:["changUrl"],
  data(){
    return {
      Product: {
        productId:nanoid(12),
        productName:"",
        productType:"",
        productStrength:"",
      },
      ProductList:[]
    }
  },
  methods:{
    add(){
      axios({
        method:"post",
        url:this.changUrl+"/api/addProduct",
        data:JSON.stringify(this.Product),
        headers: {
          'Content-Type': 'application/json'
        },
      })
      console.log(JSON.stringify(this.Product));
      alert("提交成功");
      this.Product.productId = nanoid(12);
      this.Que();
    },
    Que(){
      axios({
        method:"get",
        url:this.changUrl+"/api/queryProductList",
      }).then((respones) =>{
        this.ProductList = respones.data;
      })
    },
    deleteProduct(value){
      axios({
        method:"post",
        url:this.changUrl+"/api/deleteProduct",
        params:{
          productId:value,
        }
      })
      this.Que();
    }
  },
  beforeCreate() {
    setTimeout(()=>{
      this.Que();
    },200)
  }
}
</script>

<style scoped>

</style>