<template>
<div>
  <button @click="okOrder('true')">打印订单</button>
  <!--  要求有一个提交按键保存-->
</div>

</template>

<script>
export default {
  name: "MainFooter",
  props:["isPrint"],
  methods:{
    okOrder(bur){
      this.isPrint(bur)
    }
  }

}
</script>

<style scoped>

</style>