<template>
  <tr>
    <th>{{Product.name}}</th>
    <th>（{{Product.packCapacity}}片/件）</th>
    <th>{{Product.long}}</th>
    <th>{{Product.packNumber}}件+{{Product.otherNumber}}片</th>
    <th>{{Product.price}}</th>
    <th>{{Product.amount}}</th>
    <th v-if="isPrint !=='true'">
      <button @click="handleDelete(Product.id)">删除</button>
    </th>
  </tr>
</template>

<script>
export default {
  name: "OrderItme",
  props:["Product","isPrint","deleteTodo"],
  methods:{
    handleDelete(id){
      if(confirm('确定删除吗？')){
        //通知App组件将对应的todo对象删除
        this.deleteTodo(id)
      }
    },
  }
}
</script>

<style scoped>
th {
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  font-size: 7px;
}
</style>