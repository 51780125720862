<template>
<div>
  <form>
    <select v-model="customerUnitPrice.userId">
      <option value="">客户</option>
      <option v-for="us in userList" :key="us.userId" :value="us.userId">{{us.userName}}</option>
    </select>
    <select v-model="customerUnitPrice.productId">
      <option value="">版型</option>
      <option v-for="ps in productList" :key="ps.productId" :value="ps.productId">{{ps.productName}}</option>
    </select>
    价钱：<input v-model="customerUnitPrice.price">
  </form>
  <button @click="add()">提交</button>
  <hr>
  <h3></h3>
  <table>
    <tr>
      <th>名称</th>
      <th>单价</th>
    </tr>
    <tr v-for="ss in UserProductList" :key="ss.productId">
      <th>{{ss.productName}}</th>
      <th>{{ss.price}}</th>
      <th><button @click="deleteData(ss.productId,ss.userID)">删除</button></th>
    </tr>
  </table>
</div>
</template>

<script>
import axios from "axios";

export default {
  name: "AddPrice",
  props:["changUrl"],
  data(){
    return {
      customerUnitPrice:{
        userId:"",
        productId:"",
        price:"",
      },
      productList:[],
      userList:[],
      UserProductList:[],
    }
  },
  methods:{
    findProducts() {
      axios.get(this.changUrl+"/api/queryProductList").then((respones) =>{
        this.productList = respones.data;
      })
    },
    Que(){
      axios({
        method:"get",
        url:this.changUrl+"/api/queryUserList",
      }).then((respones) =>{
        this.userList = respones.data;
      })
    },
    add(){
      if(confirm('确定添加吗？')){
        axios({
          method:"post",
          url:this.changUrl+"/api/addustomerUnitPrice",
          data:JSON.stringify(this.customerUnitPrice),
          headers: {
            'Content-Type': 'application/json'
          },
        })
        setTimeout(()=>{
          this.find();
        },200)
      }

    },
    find(){
      axios({
        method:"get",
        url:this.changUrl+"/api/allListByUserId",
        params:{
          UserId:this.customerUnitPrice.userId,
        }
      }).then((respones) =>{
        this.UserProductList = respones.data;
      })
    },
    deleteData(productId,userId){
      if(confirm('确定删除吗？')){
        axios({
          method:"post",
          url:this.changUrl+"/api/deleteCustomerUnitPrice",
          params:{
            userId:userId,
            productId:productId,
          }
        })
        this.find();
      }
      }

  },
  watch:{
    customerUnitPrice:{
      deep:true,
      handler(){
        this.find();
      }
    }
  },
  beforeCreate() {
    setTimeout(()=>{
      this.Que();
      this.findProducts()
    },200)
  }
}
</script>

<style scoped>

</style>