<template>
<div>
<form>
  <select v-model="User.UserId" ref="test">
    <option  value="">客户</option>
    <option v-for="Us in UserList" :key="Us.userId" :value="Us.userId">{{Us.userName}}</option>
  </select>
  <hr>
<!--  板材类型-->
  <select v-model="product.type">
    <option value="">请选择类型</option>
    <option value="墙板">墙板</option>
    <option value="棚板">棚板</option>
  </select>
<!--  板材强度-->
  <select v-model="product.strength">
    <option value="">请选择类型</option>
    <option value="30">30</option>
    <option value="40">40</option>
    <option value="50">50</option>
    <option value="60">60</option>
  </select>
<!--  板材名称-->
  <div class="productBox" style="height: auto">
    <ul style="width: 100%" class="filProduct">
      <li v-for="PS in filProductList" :key="PS.productId" @click="changName(PS.productName)">{{PS.productName}}</li>
    </ul>
  </div>
  <p>
    名称：<input v-model="product.name" style="width: 120px">
    价格：<input v-model="product.price">
  </p>
  <br>
  <p>
    <!--  包数-->
    包数：<input type="number" v-model.number="product.packNumber">
    <!--  片数-->
    --片数：<input type="number" v-model.number="product.otherNumber">
    <!--  包片数-->
    <input type="number" v-model.number="product.packCapacity">片/件

  </p>
  <hr>
<!--  板材数量-->
<!--  板材米数-->
  米数：<input type="number" v-model.number="product.long">
</form>
  <button @click="add">添加</button>
</div>
</template>

<script>

import {nanoid} from 'nanoid'
import axios from "axios";


export default {
  name: "AddInput",
  data(){
    return {
      product:{
        id:"",
        name:"",
        number:0,
        price:"",
        type:"",
        strength:"",
        long:"",
        amount:0,
        packNumber:"",
        packCapacity:"",
        otherNumber:""
      },
      UserList:[],
      ProductList:[],
      User:{
        UserId:"",
        UserName:""
      },
      filProductList:[],
    }
  },
  props:["addOrder","changUrl","setUsername"],
  methods:{
    changName(val){
      this.product.name = val;
      this.filProductList=[];
    },
    add(){

      const obj = {
        id:nanoid(3),
        name:this.product.name,
        number:this.product.number,
        price:this.product.price,
        type:this.product.type,
        strength:this.product.strength,
        long:this.product.long,
        amount:Number(this.product.price*this.product.long*this.product.number*this.product.strength*0.01).toFixed(0),
        packNumber:this.product.packNumber,
        packCapacity:this.product.packCapacity,
        otherNumber:this.product.otherNumber,
      }
      this.addOrder(obj);

    },
    Que(){
      axios({
        method:"get",
        url:this.changUrl+"/api/queryUserList",
      }).then((respones) =>{
        this.UserList = respones.data;
      })
    },
    queProductList(){
      axios({
        method:"get",
        url:this.changUrl+"/api/queryProductList",
      }).then((respones) =>{
        this.ProductList = respones.data;
      })
    }
  },
  watch:{
    product:{
      deep:true,
      immediate:true,
      handler(){
        this.product.number =this.product.otherNumber+this.product.packNumber*this.product.packCapacity
        this.ProductList.forEach((item) => {
          if (this.product.name === item.productName){
            this.product.type = item.productType;
            this.product.strength = item.productStrength;
            this.product.id = item.productId;
          }
        })
      }
},
    'product.name':{
      immediate:true,
      handler(val){
        if (val === "")
          return this.filProductList = [];
        this.filProductList = this.ProductList.filter((p)=>{
          return p.productName.indexOf(val.toUpperCase()) !== -1;
        })
      }
    },
    'User.UserId':{
      immediate:true,
      handler(val){
        this.UserList.forEach((itme)=>{
            if(itme.userId === val){
              this.User.UserName= itme.userName;
            }
          // console.log(this.User.UserName+'----'+itme.userName)
        });
        this.setUsername(this.User.UserName);
      }
    }
  },
  beforeCreate() {
    setTimeout(()=>{
      this.Que();
      this.queProductList();
    },200)
  }
}
</script>

<style scoped>

input {
  width: 60px;
}
li {
  list-style: none;
  margin-left: 40px;
  width: 45%;
}
.nameInput:focus~ .filProduct{
  display: block;
}
</style>