<template>
<div>
  <form>
    客户名称：<input type="text" v-model="User.UserName">
    <hr>
    <!--  板材名称-->
    板子名称：<input type="text" v-model="product.name">
    板子单价：<input type="text" v-model="product.price">
    <select v-model="product.strength">
      <option value="">请选择板子宽度</option>
      <option value="30">30</option>
      <option value="40">40</option>
      <option value="50">50</option>
      <option value="60">60</option>
  </select>
    <hr>
    <!--  包数-->
    件数：<input type="number" v-model.number="product.packNumber">

    --片数：<input type="number" v-model.number="product.otherNumber">
    <!--  包片数-->
    <input type="number" v-model.number="product.packCapacity">片/件
    <hr>
    <!--  板材数量-->
    <!--  板材米数-->
    米数：<input type="number" v-model.number="product.long">
  </form>
  <button @click="add">添加</button>
  </div>
</template>

<script>
import {nanoid} from "nanoid";

export default {
  name: "CustomOrderInput",
  data(){
    return {
      product:{
        id:"",
        name:"",
        number:0,
        price:"",
        type:"",
        strength:"",
        long:"",
        amount:0,
        packNumber:"",
        packCapacity:"",
        otherNumber:"",
      },
      UserList:[],
      UserProductList:[],
      User:{
        UserId:"",
        UserName:"",
        UserNumber:""
      }
    }
  },
  props:["addOrder","changUrl","setUsername"],
  methods:{
    add(){

      const obj = {
        id:nanoid(3),
        name:this.product.name.toUpperCase(),
        number:this.product.number,
        price:this.product.price,
        type:this.product.type,
        strength:this.product.strength,
        long:this.product.long,
        amount:Number(this.product.price*this.product.long*this.product.number*this.product.strength*0.01).toFixed(0),
        packNumber:this.product.packNumber,
        packCapacity:this.product.packCapacity,
        otherNumber:this.product.otherNumber,
      }
      this.addOrder(obj)
      this.setUsername(this.User.UserName);
      this.product.long = "";
      this.product.otherNumber = "";
      this.product.packNumber = "";
    },
  },
  watch:{
    product:{
      deep:true,
      immediate:true,
      handler(){
      this.product.number =this.product.otherNumber+this.product.packNumber*this.product.packCapacity
      }
    }
  }
}
</script>

<style scoped>
input {
  width: 60px;
}
</style>