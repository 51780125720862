<template>
  <div>
    <div>
  <!--    要求显示是谁家的订单-->
      <h2>订单</h2>
      <table>
        <tr>
          <th>型号</th>
          <th>片/件</th>
          <th>长度</th>
          <th>数量</th>
          <th>单价</th>
          <th>金额</th>
        </tr>
  <!--      订单的每一行-->
          <OrderItme v-for="p in Product" :key="p.id" :Product="p" :deleteTodo="deleteTodo"></OrderItme>

      </table>
      <!--有一个时间戳-->
      <p>时间显示:{{getFmtTime()}}</p>
      <!--  有一个总价-->
      <p>总价：{{allAmount}}</p>
      <!--  有一个注释  用于标记其他信息-->
      其他信息：<input type="text" v-model="text">
      <!--  有一个打印按键-->
    </div>
  </div>
</template>

<script>

import OrderItme from "@/components/OrderItme";
import dayjs from "dayjs";

export default {
  name: "OrderList",
  components:{OrderItme,},
  props:["Product","setOtherText","deleteTodo"],
  data(){
    return {
      text:"",
      allAmount:0,
    }
  },
  watch:{
    text:{
      //什么时候调用 当改变时调用
      handler(newValue){
        this.setOtherText(newValue);
      },
      immediate:true,
    },
    Product:{
      immediate:true,
      handler(){
        this.allAmount = 0,
        this.Product.forEach((item) => {
          //遍历prodAllPrice这个字段，并累加
          this.allAmount += (item.amount-0);
        });
      }
    }
  },
  methods:{
    getFmtTime(){
      return dayjs(this.time).format('YYYY年MM月DD日 HH:mm:ss')
    },
  },
}
</script>

<style scoped>
table {
  border: 1px solid #ddd;
}
tr th {
  width: 120px;
}
</style>