<template>
<div>
名称：<input v-model="User.userName">
电话号：<input v-model="User.userNumber">
  <button @click="add()">提交</button>

  <table>
    <tr>
      <th>名称</th>
      <th>电话</th>
    </tr>
    <tr v-for="Us in UserList" :key="Us.userId">
      <th>{{Us.userName}}</th>
      <th>{{Us.userNumber}}</th>
      <th><button @click="deleteUser(Us.userId)">删除</button></th>
    </tr>
  </table>
</div>
</template>

<script>
import axios from "axios";
import {nanoid} from "nanoid";

export default {
  name: "addPage",
  data(){
    return {
      User:{
        userId:nanoid(12),
        userName:"",
        userNumber:"",
      },
      UserList:[]
    }
  },
  props:["changUrl"],
  methods:{
    add(){
      axios({
        method:"post",
        url:this.changUrl+"/api/addUser",
        data:JSON.stringify(this.User),
        headers: {
          'Content-Type': 'application/json'
        },
      })
      alert("提交成功");
      this.User.userId = nanoid(12)
      this.Que();
    },
    Que(){
      axios({
        method:"get",
        url:this.changUrl+"/api/queryUserList",
      }).then((respones) =>{
        this.UserList = respones.data;
      })
    },
    deleteUser(value){
      axios({
        method:"post",
        url:this.changUrl+"/api/deleteUser",
        params:{
          userId:value,
        }
      })
      this.Que();
    }
  },
  beforeCreate() {
    setTimeout(()=>{
      this.Que();
    },200)
  }
}
</script>

<style scoped>

</style>