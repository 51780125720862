<template>
<div>
  <AddInput :addOrder="addOrder" :changUrl="changUrl" v-if="print === 'false'" :setUsername="setUsername">

  </AddInput>
  <OrderList :Product="Product" :setOtherText="setOtherText" v-if="print === 'false'" :deleteTodo="deleteTodo">

  </OrderList>
  <PrintList v-if="print === 'true'" :Product="Product" :OtherText="OtherText" :userName="userName" :changUrl="changUrl">

  </PrintList>
  <MainFooter :isPrint="isPrint"></MainFooter>
</div>
</template>

<script>
import OrderList from "@/components/OrderList";
import MainFooter from "@/components/MainFooter";
import AddInput from "@/components/AddInput";
import PrintList from "@/components/PrintList";

export default {
  name: "MainForm",
  components:{OrderList,MainFooter,AddInput,PrintList},
  props:["changUrl"],
  data(){
    return {
      Product: [

      ],
      OtherText:"",
      print:"false",
      userName:"",
    }
  },
  methods:{
    //添加一个Obj
    addOrder(Obj){
      this.Product.unshift(Obj)
    },
    setOtherText(text){
      this.OtherText = text;
    },
    isPrint(val){
      this.print = val;
    },
    setUsername(text){
      this.userName = text;
    },
    deleteTodo(id){
      this.Product = this.Product.filter( Product => Product.id !== id )
    },
  }

}
</script>

<style scoped>

</style>