<template>
<div>
<table v-if="print === 'false'">
  <tr>
    <th>时间</th>
    <th>客户</th>
  </tr>
  <tr v-for="orderItme in orderList" :key="orderItme.orderId">
    <th>{{getFmtTime(orderItme.orderTime)}}</th>
    <th>{{orderItme.orderUserName}}</th>
    <th><button @click="findOrderById(orderItme.orderId,orderItme.orderUserName)">查询</button></th>
    <th><button @click="deleteOrderById(orderItme.orderId)">删除</button></th>
  </tr>
</table>
    <table v-if="print === 'false'">
      <tr>
        <th>型号</th>
        <th>片/件</th>
        <th>长度</th>
        <th>数量</th>
        <th>单价</th>
        <th>金额</th>
      </tr>
      <!--      订单的每一行-->
      <OrderItme v-for="p in Product" :key="p.id" :Product="p"></OrderItme>

    </table>
  <PrintList v-if="print === 'true'" :Product="Product" :userName="userName" :changUrl="changUrl">

  </PrintList>
  <MainFooter :isPrint="isPrint"></MainFooter>
</div>
</template>

<script>
import axios from "axios";
import OrderItme from "@/components/OrderItme.vue";
import PrintList from "@/components/PrintList.vue";
import MainFooter from "@/components/MainFooter.vue";
import dayjs from "dayjs";

export default {
  name: "FindOrder",
  data(){
    return {
      orderList:[],
      Product:[],
      findOrderList:"true",
      userName:"",
      print:"false",
    }
  },
  props:["changUrl"],
  components:{
    OrderItme,
    PrintList,
    MainFooter,
  },
  methods:{
    isPrint(val){
      this.print = val;
    },
    getFmtTime(val){
      return dayjs(val).format('YYYY年MM月DD日')
    },
    findOrder(){
      axios({
        method:"get",
        url:this.changUrl+"/api/queryOrderList",
      }).then((respones) =>{
        this.orderList = respones.data;
      })
    },
    findOrderById(orderId,userName){
      axios({
        method:"get",
        url:this.changUrl+"/api/queryOrderData",
        params:{
          orderId:orderId
        }
      }).then((respones) =>{
        console.log(respones.data)
        this.Product = respones.data;
      })
      this.userName=userName;
},
    deleteOrderById(orderId){
      if(confirm('确定删除吗？')){
        //通知App组件将对应的todo对象删除
        axios({
          method:"post",
          url:this.changUrl+"/api/deleteOrder",
          params:{
            orderId:orderId
          }
        })
        this.deleteTodo(orderId);
      }
    },
    deleteTodo(id){
      this.orderList = this.orderList.filter( orderList => orderList.orderId !== id )
    },

  },
  beforeCreate() {
    setTimeout(()=>{
      this.findOrder();
    },200)
  }

}
</script>

<style scoped>

</style>